import React, { useState } from 'react'

const usePagination = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [count, setCount] = useState(0)

  const handlePageChange = async (ev, page) => {
    setCurrentPage(page)
  }
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setCurrentPage(0)
  }

  return {currentPage, rowsPerPage, count, setCount, handlePageChange, handleRowsPerPageChange}
}

export default usePagination