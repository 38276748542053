import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {
  DaoBridge,
  Dex,
  DaoUniswap,
  NotFound,
  Panel,
  DaoTreasuryNew,
  DaoWulx,
  DaoManage,
  DaoPackages,
  DaoTreasuryOld,
  DaoRevenueShare
} from '../../routes'
import Web3Modal from 'web3modal';
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { web3modalSlice } from '../../store/reducers/web3modalSlice'
import { useSupport } from '../../hooks/useSupport'
import { availableChainIds, availableChains, ultronChainId } from '../../constant/chain/chain'
import { walletSlice } from '../../store/reducers/walletSlice'

const WalletConnectProvider = window.WalletConnectProvider?.default;

const newWeb3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: {
          1230: 'https://ultron-dev.io',
          1231: 'https://ultron-rpc.net',
          56: 'https://bscrpc.com',
          1: 'https://rpc.ankr.com/eth',
          43114: 'https://rpc.ankr.com/avalanche',
          137: 'https://polygon-rpc.com',
          250: 'https://rpc.ankr.com/fantom',
          97: 'https://data-seed-prebsc-1-s3.binance.org:8545',
          4002: 'https://rpc.testnet.fantom.network',
        },
        infuraId: '63cf6a7932394e5db4815c21cacc10e4',
        bridge: 'https://bridge.walletconnect.org',
        chainId: ultronChainId,
        // network: 'matic',
        qrcode: true,
        qrcodeModalOptions: {
          mobileLinks: [
            'metamask'
          ],
        },
      }
    }
  }
})

const App = () => {

  const dispatch = useAppDispatch()
  const {setWeb3Modal} = web3modalSlice.actions;
  const {web3modal} = useAppSelector(state => state.web3modalReducer)
  const {isConnect} = useAppSelector(state => state.walletReducer)

  const {connectWallet} = useSupport()
  const {setWalletInfo} = walletSlice.actions

  useEffect(() => {
    dispatch(setWeb3Modal(newWeb3Modal))
  }, [])

  useEffect(() => {
    if(web3modal){
      // @ts-ignore
      (async () => {
        // @ts-ignore
        if(web3modal.cachedProvider){
          // @ts-ignore
          const { provider } = await connectWallet();
          if (window.ethereum) {
            window.ethereum.request({
              'jsonrpc': '2.0',
              'method': 'wallet_addEthereumChain',
              'params': [
                availableChains[ultronChainId]
              ]
            }).then(async () => {
              await initUser()
              setTimeout(() => {
                // @ts-ignore
                provider.on('chainChanged', async (chainId) => {
                  console.log('chainChanged: ', chainId)
                  if (availableChainIds.includes(chainId)) {
                    await initUser();
                  }
                });

                // @ts-ignore
                provider.on('accountsChanged', async () => {
                  await initUser();
                  console.log('Account changed')
                });
              }, 2000)
            }).catch(() => {
              // @ts-ignore
              provider.on('chainChanged', async (chainId) => {
                console.log('chainChanged: ', chainId)
                if (availableChainIds.includes(chainId)) {
                  await initUser();
                }
              });

              // @ts-ignore
              provider.on('accountsChanged', async () => {
                await initUser();
                console.log('Account changed')
              });
            })
          }
          // // @ts-ignore
          // provider.on('connect', async (info: any) => {
          //   console.log('Connect: ', info)
          // });
          // @ts-ignore
          // provider.on('disconnect', async (info: any) => {
          //   console.log('disconnect: ', info)
          //   dispatch(setWalletInfo({isConnect: false, address: ''}))
          // })
        }
      })()
    }

  }, [web3modal, isConnect])

  const initUser = async () => {
    const { signerAddress } = await connectWallet();
    dispatch(setWalletInfo({isConnect: true, address: signerAddress}))
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Panel />}>
          <Route path='/' element={<DaoBridge />} />
          <Route path='dex' element={<Dex />} />
          <Route path='dao-uniswap' element={<DaoUniswap />} />
          <Route path='dao-treasury-new' element={<DaoTreasuryNew />} />
          <Route path='dao-treasury-old' element={<DaoTreasuryOld />} />
          <Route path='dao-wulx' element={<DaoWulx />} />
          <Route path='dao-manage' element={<DaoManage />} />
          <Route path='dao-packages' element={<DaoPackages />} />
          <Route path='dao-revenue-share' element={<DaoRevenueShare />} />
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
