import { find, sortBy } from 'lodash'
import { BigNumber, ethers, utils } from 'ethers'
import { errorHandler, getSlotKey, tokenAmountToInt } from '../utils/helpers'

export const useDAO = (provider) => {

  const getStartEnd = (currentPage, rowsPerPage, lastIdNumber) => {
    const start = currentPage === 0 ? 1 : currentPage * rowsPerPage + 1
    let end = lastIdNumber
    if(currentPage === 0 && lastIdNumber > rowsPerPage){
      end = rowsPerPage
    } else if((currentPage * rowsPerPage) > lastIdNumber){
      end = currentPage * rowsPerPage
    }
    return {start, end}
  }

  const makeDataForNewDao = async (currentDao, voterCount, signer, rowsPerPage, currentPage) => {
    const res = []
      const lastId = await currentDao.getCounterRequest()
      const lastIdNumber = typeof lastId === 'number' ? lastId : lastId.toNumber()

      const {start, end} = getStartEnd(currentPage, rowsPerPage, lastId)

    for(let i = start; i <= end; i++){
        const id = lastId - (i-1)
        const request = await currentDao.getRequest(id)
        const parsedData = await currentDao.parseData(request, signer)
        const votes = await currentDao.getRequestVotes(id)
        let isAvailable = false
        try {
          isAvailable = currentDao.isRequestAvailable ?
            await currentDao.isRequestAvailable(id)
            :
            (Math.floor(voterCount/2) + 1) <= votes.toNumber()
        }catch (err) {
          // console.log(err)
        }
        const dataObj = {
          ...parsedData,
          votes: votes.toNumber(),
          isAvailable,
          type: currentDao.type,
          id: id,
          haveCancelFunc: !!currentDao.cancelRequest,
          hasRejectedButton: currentDao.trueFalseVote
        }
        res.push(dataObj)
      }
    return { tableData: res, lastId: lastIdNumber }
  }

  const makeTableData = async (currentDao, voterCount, votersList, daoAddress, rowsPerPage, currentPage) => {
    const res = []
    const storageSlot: BigNumber = BigNumber.from(5);
      const lastId = await currentDao.getCounterRequest()

      const {start, end} = getStartEnd(currentPage, rowsPerPage, lastId)
      for(let i = start; i <= end; i++){
        const id = lastId - (i-1)
        const request = await currentDao.getRequest(id)
        const parsedData = currentDao.parseData(request)
        let isAvailable = false
        try {
          isAvailable = currentDao.isRequestAvailable && await currentDao.isRequestAvailable(i)
        }catch (err) {
          console.log(err)
        }
        const dataObj = {
          requestData: parsedData.requestData,
          status: parsedData.status,
          haveCancelFunc: !!currentDao.cancelRequest,
          hasRejectedButton: currentDao.trueFalseVote,
          isAvailable: isAvailable ? isAvailable[1] : false,
          type: currentDao.type,
          id: id,
          votes: 0,
        }
        let voteCount = 0
        const key0 = BigNumber.from(id);
        for(let j = 0; j < voterCount; j++) {
          const voteData = await getVoterData(daoAddress, key0, storageSlot, votersList[j]);
          voteCount = BigNumber.from(voteData).toNumber() ? voteCount+1 : voteCount
        }
        dataObj['votes'] = voteCount
        dataObj.isAvailable = ((voterCount / 2) + 1) <= voteCount
        res.push(dataObj)
      }
    return { tableData: res, lastId }
  }

  const getCurrentVoters = async (dao) => {
    const voterCount = await dao.getActiveVotersCount()

    const votersList = []
    for(let i = 0; i<=voterCount.toNumber() -1 ; i++){
      const voterAddress = await dao.getVoterById(i)
      votersList.push(voterAddress)
    }
    return { votersList, voterCount }
  }

  const cancelRequest = async (currentDao, id, setAlert) => {
    try {
      await currentDao.cancelRequest(id)
    }catch (err) {
      errorHandler(setAlert, err)
    }
  }

  const approveRequest = async (currentDao, id, setAlert) => {
    if(currentDao.approveRequest){
      try {
        await currentDao.approveRequest(id)
      }catch (err) {
        errorHandler(setAlert, err)
      }
    } else {
      setAlert({open: true, type: 'error', text: 'dao dont have this function'})
    }
  }

  const sendVote = async (currentDao, type, id, setAlert, voteType) => {
    try {
      if(currentDao.trueFalseVote) {
        await currentDao.makeVote(voteType, id)
      } else await currentDao.makeVote(id)
    }catch (err) {
      errorHandler(setAlert, err)
    }
  }

  const getCurrentDao = (daoObj, daoType) => {
    return find(daoObj, {type: daoType})
  }

  // const getProvider = () => {
  //   // @ts-ignore
  //   const provider = new ethers.providers.JsonRpcProvider(rpcUrls[chainId]);
  //   return provider
  // }

  const getLastID = async (slot, address) => {
    const paddedSlot = utils.hexZeroPad(slot, 32)
    const storageLocation = await provider.getStorageAt(address, paddedSlot)
    const storageValue = BigNumber.from(storageLocation);
    return storageValue.toNumber()
  }

  const getDAOData = async (key, slot, address) => {
    const newKey =  getSlotKey(key, slot);
    const value = await provider.getStorageAt(address, newKey);
    return value
  }

  const getTransferNativeRequestData = async (key, slot, daoAddress) => {
    const newKey =  await getSlotKey(key, slot);

    // address
    const structSlot0 = BigNumber.from(newKey);
    const address = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot0));

    const structSlot1 = BigNumber.from(newKey).add(1);
    const amount = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot1));
    const intAmount = ethers.utils.formatUnits(amount, 18)

    const structSlot2 = BigNumber.from(newKey).add(2);
    const status = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot2));

    return { address: address.toHexString(), amount: intAmount, status: status.toNumber() }
  }

  const getTransferRequestData = async (key, slot, daoAddress, signer) => {
    const newKey =  await getSlotKey(key, slot);

    // address
    const structSlot0 = BigNumber.from(newKey);
    const address = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot0));

    // token address
    const structSlot1 = BigNumber.from(newKey).add(1);
    const tokenAddress = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot1));

    // amount
    const structSlot2 = BigNumber.from(newKey).add(2);
    const amount = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot2));
    const intAmount = await tokenAmountToInt(tokenAddress.toHexString(), amount, signer)

    // status
    const structSlot3 = BigNumber.from(newKey).add(3);
    const status = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot3));

    return {address: address.toHexString(), tokenAddress: tokenAddress.toHexString(), amount: intAmount, status: status.toNumber()}
  }

  const getVoterData = async (daoAddress:string, key0: BigNumber, storageSlot:BigNumber, key1:string) => {
    // const provider = getProvider();
    const newKeyPreimage = ethers.utils.concat([
      // Mappings' keys in Solidity must all be word-aligned (32 bytes)
      ethers.utils.hexZeroPad(key0.toHexString(), 32),

      // Similarly with the slot-index into the Solidity variable layout
      ethers.utils.hexZeroPad(storageSlot.toHexString(), 32),
    ]);

    const newKey = ethers.utils.keccak256(newKeyPreimage)

    const newKeyPreimage1 = ethers.utils.concat([
      // Mappings' keys in Solidity must all be word-aligned (32 bytes)
      ethers.utils.hexZeroPad(key1.toString(), 32),

      // Similarly with the slot-index into the Solidity variable layout
      ethers.utils.hexZeroPad(newKey.toString(), 32),
    ]);

    const newKey1 = ethers.utils.keccak256(newKeyPreimage1)

    // keccak256(uint256(9) . keccak256(uint256(4) . uint256(1)))
    const res = await provider.getStorageAt(daoAddress, newKey1);
    return res;
  }


  return {
    makeTableData, getCurrentVoters, cancelRequest, approveRequest, sendVote,
    getCurrentDao, makeDataForNewDao,
    getLastID, getDAOData, getTransferNativeRequestData, getTransferRequestData, getVoterData
  }

}