import { parseTokenAmount, parseTokenAmountWithDecimals, tokenAmountToInt } from '../helpers'
import { ethers } from 'ethers'
import { useDAO } from '../../hooks/useDAO'

const makeDAORevenueShareObj = (dao, revenueShare, daoAddress, provider) => {
    const {getLastID} = useDAO(provider)

    const mapDAO = [
        {
            type: 'voterRequest',
            name: 'Voter',
            trueFalseVote: true,
            getCounterRequest: async () => await getLastID(6, daoAddress),
            getRequest: async (id) => await dao.getVoterRequest(id),
            getRequestVotes: async (id) => await dao.countGetVotersAffirmativeVotes(id),
            makeVote: async (type, id) => await dao.newVoteForVoterRequest(type, id),
            cancelRequest: async (id) => await dao.cancelVoterRequest(id),
            approveRequest: async (id) => await dao.changeThresholdRequestConclusion(id),
            createNewProposal: async (proposalData) => await dao.newVoterRequest(!!proposalData.include, proposalData.voterAddress),
            parseData: (data) => {
                return {
                    status: data.status,
                    requestData: [
                        {label: data.include ? 'Add voter': 'Remove voter'},
                        {label: `Address: ${data.candidate}`}
                    ]
                }
            },
            newProposalSchema: [
                {name: 'Include?', xs: 2, type: 'boolean', defaultValue: 1, daoName: 'tokenAddress', formLink: 'include'},
                {name: 'Voter Address', xs: 4, type: 'text', defaultValue: '', daoName: 'tokenAddress', formLink: 'voterAddress'}
            ]
        },
        {
            type: 'ChangeDAORequest',
            name: 'DAO Change',
            trueFalseVote: true,
            getCounterRequest: async () => await dao.getChangeDAORequestsCounter(),
            getRequest: async (id) => await dao.getChangeDAORequest(id),
            getRequestVotes: async (id) => await dao.countGetChangeDAOAffirmativeVotes(id),
            makeVote: async (type, id) => await dao.newVoteForChangeDAORequest(type, id),
            cancelRequest: async (id) => await dao.cancelChangeDAORequest(id),
            isRequestAvailable: async (id) => await dao.isChangeDAOAvailable(id),
            approveRequest: async (id) => await dao.confirmChangeDAORequest(id),
            createNewProposal: async (proposalData) =>
                await dao.newChangeDAORequest(proposalData.address),
            parseData: (data) => {
                return {
                    status: data.status,
                    requestData: [
                        {label: `Address: ${data.dao}`}
                    ]
                }
            },
            newProposalSchema: [
                {name: 'Address', xs: 4, type: 'text', defaultValue: '', daoName: 'address', formLink: 'address'},
            ]
        },
        {
            type: 'ChangeNFTRequest',
            name: 'NFT Change',
            trueFalseVote: true,
            getCounterRequest: async () => await dao.getChangeNFTRequestsCounter(),
            getRequest: async (id) => await dao.getChangeNFTRequest(id),
            getRequestVotes: async (id) => await dao.countGetChangeNFTAffirmativeVotes(id),
            makeVote: async (type, id) => await dao.newVoteForChangeNFTRequest(type, id),
            cancelRequest: async (id) => await dao.cancelChangeNFTRequest(id),
            isRequestAvailable: async (id) => await dao.isChangeNFTAvailable(id),
            approveRequest: async (id) => await dao.confirmChangeNFTRequest(id),
            createNewProposal: async (proposalData) =>
                await dao.newChangeNFTRequest(proposalData.address),
            parseData: (data) => {
                return {
                    status: data.status,
                    requestData: [
                        {label: `Address: ${data.nft}`}
                    ]
                }
            },
            newProposalSchema: [
                {name: 'Address', xs: 4, type: 'text', defaultValue: '', daoName: 'address', formLink: 'address'},
            ]
        },
        {
            type: 'ChangeOwnerRequest',
            name: 'Owner Change',
            trueFalseVote: true,
            getCounterRequest: async () => await dao.getChangeOwnerRequestsCounter(),
            getRequest: async (id) => await dao.getChangeOwnerRequest(id),
            getRequestVotes: async (id) => await dao.countGetChangeOwnerAffirmativeVotes(id),
            makeVote: async (type, id) => await dao.newVoteForChangeOwnerRequest(type, id),
            cancelRequest: async (id) => await dao.cancelChangeOwnerRequest(id),
            isRequestAvailable: async (id) => await dao.isChangeOwnerAvailable(id),
            approveRequest: async (id) => await dao.confirmChangeOwnerRequest(id),
            createNewProposal: async (proposalData) =>
                await dao.newChangeOwnerRequest(proposalData.address),
            parseData: (data) => {
                return {
                    status: data.status,
                    requestData: [
                        {label: `Address: ${data.owner}`}
                    ]
                }
            },
            newProposalSchema: [
                {name: 'Address', xs: 4, type: 'text', defaultValue: '', daoName: 'address', formLink: 'address'},
            ]
        },
        {
            type: 'ChangeThresholdRequest',
            name: 'Threshold Change',
            trueFalseVote: true,
            getCounterRequest: async () => await dao.getChangeThresholdRequestsCounter(),
            getRequest: async (id) => await dao.getChangeThresholdRequest(id),
            getRequestVotes: async (id) => await dao.countGetChangeThresholdAffirmativeVotes(id),
            makeVote: async (type, id) => await dao.newVoteForChangeThresholdRequest(type, id),
            cancelRequest: async (id) => await dao.cancelChangeThresholdRequest(id),
            isRequestAvailable: async (id) => await dao.isChangeThresholdAvailable(id),
            approveRequest: async (id) => await dao.confirmChangeThresholdRequest(id),
            createNewProposal: async (proposalData) =>
                await dao.newChangeThresholdRequest(proposalData.amount),
            parseData: (data) => {
                const amount = ethers.utils.formatUnits(data.amount, 18)

                return {
                    status: data.status,
                    requestData: [
                        {label: `Amount: ${amount}`}
                    ]
                }
            },
            newProposalSchema: [
                {name: 'Amount', xs: 4, type: 'text', defaultValue: '', daoName: 'amount', formLink: 'amount'},
            ]
        },
        {
            type: 'ChangeTokenRequest',
            name: 'Token Change',
            trueFalseVote: true,
            getCounterRequest: async () => await dao.getChangeTokenRequestsCounter(),
            getRequest: async (id) => await dao.getChangeTokenRequest(id),
            getRequestVotes: async (id) => await dao.countGetChangeTokenAffirmativeVotes(id),
            makeVote: async (type, id) => await dao.newVoteForChangeTokenRequest(type, id),
            cancelRequest: async (id) => await dao.cancelChangeTokenRequest(id),
            isRequestAvailable: async (id) => await dao.isChangeTokenAvailable(id),
            approveRequest: async (id) => await dao.confirmChangeTokenRequest(id),
            createNewProposal: async (proposalData) =>
                await dao.newChangeTokenRequest(proposalData.address),
            parseData: (data) => {
                return {
                    status: data.status,
                    requestData: [
                        {label: `Address: ${data.token}`}
                    ]
                }
            },
            newProposalSchema: [
                {name: 'Address', xs: 4, type: 'text', defaultValue: '', daoName: 'address', formLink: 'address'},
            ]
        },
        {
            type: 'WithdrawalRequest',
            name: 'Withdrawal',
            trueFalseVote: true,
            getCounterRequest: async () => await dao.getWithdrawalRequestsCounter(),
            getRequest: async (id) => await dao.getWithdrawalRequest(id),
            getRequestVotes: async (id) => await dao.countGetWithdrawalAffirmativeVotes(id),
            makeVote: async (type, id) => await dao.newVoteForWithdrawalRequest(type, id),
            cancelRequest: async (id) => await dao.cancelWithdrawalRequest(id),
            isRequestAvailable: async (id) => await dao.isWithdrawalAvailable(id),
            approveRequest: async (id) => await dao.confirmWithdrawalRequest(id),
            createNewProposal: async (proposalData) =>
                await dao.newWithdrawalRequest(proposalData.address),
            parseData: (data) => {
                const epoch = ethers.utils.formatUnits(data.epoch, 18);
                const amount = ethers.utils.formatUnits(data.amount, 18);

                return {
                    status: data.status,
                    requestData: [
                        {label: `Epoch: ${epoch}`},
                        {label: `Amount: ${amount}`},
                        {label: `To address: ${data.to}`},
                        {label: data.is_raw_withdrawal ? 'Is raw withdrawal': 'Is not raw withdrawal'},
                        {label: `Is raw withdrawal: ${data.is_raw_withdrawal}`},
                    ]
                }
            },
            newProposalSchema: [
                {name: 'Epoch', xs: 4, type: 'text', defaultValue: '', daoName: '_epoch', formLink: '_epoch'},
                {name: 'Amount', xs: 4, type: 'text', defaultValue: '', daoName: '_amount', formLink: '_amount'},
                {name: 'Is row?', xs: 2, type: 'boolean', defaultValue: 1, daoName: '_is_raw', formLink: '_is_raw'},
                {name: 'Address', xs: 4, type: 'text', defaultValue: '', daoName: '_address', formLink: '_address'},
            ]
        },
    ]
    return mapDAO
}

export default makeDAORevenueShareObj;
