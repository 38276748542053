import React from 'react'
import { Snackbar, Alert } from '@mui/material'

const DefaultAlert = ({ open, onClose, type, text }: any) => {

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <Alert onClose={onClose} severity={type} sx={{ width: '100%' }}>
        {text}
      </Alert>
    </Snackbar>
  )
}

export default DefaultAlert