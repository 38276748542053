import { parseTokenAmount, parseTokenAmountWithDecimals, tokenAmountToInt } from '../helpers'
import { ethers } from 'ethers'
import { useDAO } from '../../hooks/useDAO'

export const makeDAOTreasuryObj = (dao, treasury, daoAddress, provider) => {
  const {getLastID} = useDAO(provider)

  const mapDAO = [
    {
      type: 'voterRequest',
      name: 'Voter',
      trueFalseVote: true,
      getCounterRequest: async () => await getLastID(6, daoAddress),
      getRequest: async (id) => await dao.getVoterRequest(id),
      getRequestVotes: async (id) => await dao.countGetVotersAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForVoterRequest(type, id),
      cancelRequest: async (id) => await dao.cancelVoterRequest(id),
      approveRequest: async (id) => await dao.votersRequestConclusion(id),
      createNewProposal: async (proposalData) => await dao.newVoterRequest(!!proposalData.include, proposalData.voterAddress),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            {label: data.include ? 'Add voter': 'Remove voter'},
            {label: `Address: ${data.candidate}`}
          ]
        }
      },
      newProposalSchema: [
        {name: 'Include?', xs: 2, type: 'boolean', defaultValue: 1, daoName: 'tokenAddress', formLink: 'include'},
        {name: 'Voter Address', xs: 4, type: 'text', defaultValue: '', daoName: 'tokenAddress', formLink: 'voterAddress'}
      ]
    },
    {
      type: 'DAOChangeRequest',
      name: 'DAO Change',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getDAOChangeRequestCounter(),
      getRequest: async (id) => await dao.getDAOChangeRequest(id),
      getRequestVotes: async (id) => await dao.countGetChangeDAOAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForDAOChangeRequest(type, id),
      cancelRequest: async (id) => await dao.cancelDAOChangeRequest(id),
      isRequestAvailable: async (id) => await dao.isDAOChangeAvailable(id),
      approveRequest: async (id) => await dao.confirmDAOChangeRequest(id),
      createNewProposal: async (proposalData) =>
        await dao.newDAOAddressChangeRequest(proposalData.address),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            {label: `Address: ${data.newDAOAddress}`}
          ]
        }
      },
      newProposalSchema: [
        {name: 'Address', xs: 4, type: 'text', defaultValue: '', daoName: 'address', formLink: 'address'},
      ]
    },
    {
      type: 'transferNativeRequest',
      name: 'Transfer Native',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getTransferNativeRequestCounter(),
      getRequest: async (id) => await dao.getTransferNativeRequest(id),
      getRequestVotes: async (id) => await dao.countGetTransferNativeAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForTransferNativeRequest(type, id),
      cancelRequest: async (id) => await dao.cancelTransferNativeRequest(id),
      isRequestAvailable: async (id) => await dao.isTransferNativeAvailable(id),
      approveRequest: async (id) => await treasury.withdrawalNative(id),
      createNewProposal: async (proposalData) => {
        const amount = parseTokenAmount(proposalData.amount, 18)
        return await dao.newTransferNativeRequest(proposalData.recepient, amount)
      },
      parseData: (data) => {
        const amount = ethers.utils.formatUnits(data.amount, 18)
        return {
          status: data.status,
          requestData: [
            {label: `Address: ${data.recepient}`},
            {label: `Amount: ${amount}`}
          ]
        }
      },
      newProposalSchema: [
        {name: 'Recepient', xs: 4, type: 'text', defaultValue: '', daoName: 'recepient', formLink: 'recepient'},
        {name: 'Amount', xs: 4, type: 'text', defaultValue: '', daoName: 'amount', formLink: 'amount'},
      ]
    },
    {
      type: 'transferTokensRequest',
      name: 'Transfer Tokens',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getTransferTokensRequestCounter(),
      getRequest: async (id) => await dao.getTransferTokensRequest(id),
      getRequestVotes: async (id) => await dao.countGetTransferTokensAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForTransferTokensRequest(type, id),
      cancelRequest: async (id) => await dao.cancelTransferTokensRequest(id),
      isRequestAvailable: async (id) => await dao.isTransferTokensAvailable(id),
      approveRequest: async (id) => await treasury.withdrawalTokens(id),
      createNewProposal: async (proposalData, signer) => {
        const amount = await parseTokenAmountWithDecimals(proposalData.token, proposalData.amount, signer)
        return await dao.newTransferTokensRequest(proposalData.recepient, proposalData.token, amount)
      },
      parseData: async (data, signer) => {
        const amount = await tokenAmountToInt(data.token, data.amount, signer)
        return {
          status: data.status,
          requestData: [
            {label: `Address: ${data.recepient}`},
            {label: `Token address: ${data.token}`},
            {label: `Amount: ${amount}`}
          ]
        }
      },
      newProposalSchema: [
        {name: 'Recepient', xs: 4, type: 'text', defaultValue: '', daoName: 'recepient', formLink: 'recepient'},
        {name: 'Token', xs: 4, type: 'text', defaultValue: '', daoName: 'token', formLink: 'token'},
        {name: 'Amount', xs: 4, type: 'text', defaultValue: '', daoName: 'amount', formLink: 'amount'},

      ]
    }
  ]
  return mapDAO
}