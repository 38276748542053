import { BigNumber, ethers } from 'ethers'

import { useDAO } from './useDAO'
import { tokenAmountToInt } from '../utils/helpers'

export const useBridgeDAO = (provider) => {

  const {getCurrentVoters, getVoterData} = useDAO(provider)

  // const provider = new ethers.providers.JsonRpcProvider('https://ultron-dev.io');

  const checkAvailable = async (request, id) => {
    let isAvailable
    try {
      isAvailable = await request(id)
    } catch (err){
      isAvailable = false
    }
    return isAvailable
  }

  const getStructSlot = async (key: BigNumber, storageSlot: BigNumber) => {
// The pre-image used to compute the Storage location
    const newKeyPreimage = ethers.utils.concat([
      // Mappings' keys in Solidity must all be word-aligned (32 bytes)
      ethers.utils.hexZeroPad(key.toHexString(), 32),

      // Similarly with the slot-index into the Solidity variable layout
      ethers.utils.hexZeroPad(storageSlot.toHexString(), 32),
    ]);

    const newKey =  ethers.utils.keccak256(newKeyPreimage);

    return newKey;
  }

  const getStartEnd = (currentPage, rowsPerPage, lastIdNumber) => {
    const start = currentPage === 0 ? 1 : currentPage * rowsPerPage + 1
    let end = lastIdNumber
    if(currentPage === 0 && lastIdNumber > rowsPerPage){
      end = rowsPerPage
    } else if((currentPage * rowsPerPage) > lastIdNumber){
      end = currentPage * rowsPerPage
    }
    return {start, end}
  }

  const getVoterStruct = async (dao, daoAddress: string, storageSlot: BigNumber, rowsPerPage, currentPage) => {
    const counter = BigNumber.from(await provider.getStorageAt(daoAddress, 6)).toNumber();
    const votersSlot = BigNumber.from(5)
    const list = []
    const { votersList, voterCount } = await getCurrentVoters(dao)
    const {start, end} = getStartEnd(currentPage, rowsPerPage, counter)
    for(let i = start; i <= end; i++) {
      const id = counter - (i-1)

      const key = BigNumber.from(id);

      const newKey = await getStructSlot(key, storageSlot)

      const structSlot0 = BigNumber.from(newKey);
      const statusAndIncludeAndVoterAddress = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot0));
      // 0x0201c3db0f3299b9de5df506545b98e5c66043a598f5 where 0x02 - status; 01 - include; c3db0f3299b9de5df506545b98e5c66043a598f5 - voterAddress

      let status = 0;
      let include = 0;
      let address = '';
      const statusAndIncludeAndVoterAddressString = statusAndIncludeAndVoterAddress.toHexString()

      if(statusAndIncludeAndVoterAddressString.length === 46) {
        status =  parseInt(statusAndIncludeAndVoterAddressString.substr(0, 4))
        include =  parseInt(`0x${statusAndIncludeAndVoterAddressString.substr(4, 2)}`)
        address = `0x${statusAndIncludeAndVoterAddressString.substr(6, 40)}`
      } else if(statusAndIncludeAndVoterAddressString.length == 44) {
        include =  parseInt(statusAndIncludeAndVoterAddressString.substr(0, 4))
        address = `0x${statusAndIncludeAndVoterAddressString.substr(4, 40)}`
      } else {
        include = 0
        address = statusAndIncludeAndVoterAddressString
      }

      let voteCount = 0
      const key0 = BigNumber.from(id);
      for(let j = 0; j < voterCount; j++) {
        const voteData = await getVoterData(daoAddress, key0, votersSlot, votersList[j]);
        voteCount = BigNumber.from(voteData).toNumber() ? voteCount+1 : voteCount
      }
      list.push({
        id: id,
        status: status,
        requestData: [
          {label: include ? 'Add voter': 'Remove voter'},
          {label: `Address: ${address}`}
        ],
        type: 'voterRequest',
        votes: voteCount,
        isAvailable: ((voterCount / 2) + 1) <= voteCount,
        hasRejectedButton: true,
        haveCancelFunc: true,
      })
    }
    return { dataList: list, lastId: counter }
  }

  // @ts-ignore
  const  getOwnerChangeStruct = async (dao, daoAddress: string, storageSlot: BigNumber, rowsPerPage, currentPage) => {

    const counter = await dao.getOwnerChangeRequestCount();
    const list = []
    const {start, end} = getStartEnd(currentPage, rowsPerPage, counter.toNumber())

    for(let i = start; i <= end; i++) {
      const id = counter - (i-1)

      const key = BigNumber.from(id);

      const newKey = await getStructSlot(key, storageSlot)

      const structSlot0 = BigNumber.from(newKey);
      const statusAndOwnerAddress = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot0));
      const votes = await dao.countGetChangeOwnerAffirmativeVotes(id)
      const isAvailable = await checkAvailable(dao.isOwnerChangeAvailable, id)
      // 0x01cafecafecafecafecafecafecafecafecafecafe where 0x01 - status; cafecafecafecafecafecafecafecafecafecafe - ownerAddress
      const statusAndOwnerAddressString = statusAndOwnerAddress.toHexString()
      let status = 0;
      let address = '';

      if(statusAndOwnerAddressString.length > 42) {
        status =  parseInt(statusAndOwnerAddressString.substr(0, 4), 16)
        address = `0x${statusAndOwnerAddressString.substr(4, 40)}`
      } else {
        address = statusAndOwnerAddressString
      }
      // todo need to check
      // @ts-ignore
      list.push({
        id: id,
        status: status,
        requestData: [
          {label: `Address: ${address}`}
        ],
        type: 'OwnerChangeRequest',
        address: address,
        votes: votes.toNumber(),
        hasRejectedButton: true,
        haveCancelFunc: true,
        isAvailable
      })
    }
    return { dataList: list, lastId: counter }
  }

  const getTransferStruct = async (dao, daoAddress: string, storageSlot: BigNumber, signer: any, rowsPerPage, currentPage) => {

    const counter = await dao.getTransferRequestCount();
    const list = []
    const {start, end} = getStartEnd(currentPage, rowsPerPage, counter.toNumber())

    for(let i = start; i <= end; i++) {
      const id = counter - (i-1)

      const key = BigNumber.from(id);

      const newKey = await getStructSlot(key, storageSlot)
      const votes = await dao.countGetTransferAffirmativeVotes(id)
      const isAvailable = await checkAvailable(dao.isTransferAvailable, id)
      const structSlot0 = BigNumber.from(newKey);
      const addressesLength = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot0));

      const addressesKey = BigNumber.from(ethers.utils.keccak256(structSlot0.toHexString()));
      const addressList = []
      for(let i = 0; i < addressesLength.toNumber(); i++) {
        const addressSlot = addressesKey.add(i);
        const address = BigNumber.from(await provider.getStorageAt(daoAddress, addressSlot));
        addressList.push(address.toHexString())
      }

      const structSlot1 = BigNumber.from(newKey).add(1);
      const amountsLength = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot1));
      const amountsList = []
      const amountsKey = BigNumber.from(ethers.utils.keccak256(structSlot1.toHexString()));
      for(let i = 0; i < amountsLength.toNumber(); i++) {
        const amountsSlot = amountsKey.add(i);
        const amount = BigNumber.from(await provider.getStorageAt(daoAddress, amountsSlot));
        const intAmount = await tokenAmountToInt(addressList[i], amount, signer)
        amountsList.push(intAmount)
      }

      const structSlot2 = BigNumber.from(newKey).add(2);
      const status = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot2));
      list.push({
        id: id,
        status: parseInt(status.toHexString(), 16),
        requestData: addressList.map((el, index) => ({label: `${el}: ${amountsList[index]}`})),
        type: 'TransferRequest',
        votes: votes.toNumber(),
        hasRejectedButton: true,
        haveCancelFunc: true,
        isAvailable
      })
    }
    console.log(2)
    return { dataList: list, lastId: counter }
  }

  const getPauseStatusStruct = async (dao, daoAddress: string, storageSlot: BigNumber, rowsPerPage, currentPage) => {

    const counter = await dao.getPauseStatusRequestCount();
    const list = []
    const {start, end} = getStartEnd(currentPage, rowsPerPage, counter.toNumber())

    for(let i = start; i <= end; i++) {
      const id = counter - (i-1)
      const key = BigNumber.from(id);

      const newKey = await getStructSlot(key, storageSlot)
      const votes = await dao.countGetPauseStatusAffirmativeVotes(id)
      const isAvailable = await checkAvailable(dao.isPauseStatusAvailable, id)
      const structSlot0 = BigNumber.from(newKey);
      const statusAndPauseStatus = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot0));
      // 0x0101 where 0x01 - status; 01 - pauseStatus(true)
      const statusAndPauseStatusString = statusAndPauseStatus.toHexString()
      let status = 0
      let pauseStatus = 0
      if(statusAndPauseStatusString.length > 4){
        status = parseInt(statusAndPauseStatusString.substr(0,4))
        pauseStatus = parseInt(`0x${statusAndPauseStatusString.substr(4,2)}`, 16)
      } else {
        pauseStatus = parseInt(statusAndPauseStatusString, 16)
      }
      list.push({
        id: id,
        status: status,
        requestData: [
          {label: `Pause status: ${pauseStatus ? 'true' : 'false'}`}
        ],
        type: 'PauseStatusRequest',
        votes: votes.toNumber(),
        hasRejectedButton: true,
        haveCancelFunc: true,
        isAvailable
      })
    }
    return { dataList: list, lastId: counter }
  }

  const getChangeRelayerThresholdStruct = async (dao, daoAddress: string, storageSlot: BigNumber, rowsPerPage, currentPage) => {

    const counter = await dao.getChangeRelayerThresholdRequestCount();
    const list = []
    const {start, end} = getStartEnd(currentPage, rowsPerPage, counter.toNumber())

    for(let i = start; i <= end; i++) {
      const id = counter - (i-1)

      const key = BigNumber.from(id);

      const newKey = await getStructSlot(key, storageSlot);
      const votes = await dao.countGetChangeRelayerThresholdAffirmativeVotes(id)
      const isAvailable = await checkAvailable(dao.isChangeRelayerThresholdAvailable, id)
      const structSlot0 = BigNumber.from(newKey);
      const treshold = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot0));

      const structSlot1 = BigNumber.from(newKey).add(1);
      const status = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot1));
      list.push({
        id: id,
        status: parseInt(status.toHexString(), 16),
        requestData: [
          {label: `Treshold: ${treshold.toNumber()}`}
        ],
        type: 'ChangeRelayerThresholdRequest',
        votes: votes.toNumber(),
        hasRejectedButton: true,
        haveCancelFunc: true,
        isAvailable
      })
    }
    return { dataList: list, lastId: counter }
  }

  const getSetResourceStruct = async (dao, daoAddress: string, storageSlot: BigNumber, rowsPerPage, currentPage) => {

    const counter = await dao.getSetResourceRequestCount();
    const list = []
    const {start, end} = getStartEnd(currentPage, rowsPerPage, counter.toNumber())

    for(let i = start; i <= end; i++) {
      const id = counter - (i-1)

      const key = BigNumber.from(id);

      const newKey = await getStructSlot(key, storageSlot)
      const votes = await dao.countGetSetResourceAffirmativeVotes(id)
      const isAvailable = await checkAvailable(dao.isSetResourceAvailable, id)

      const structSlot0 = BigNumber.from(newKey);
      const handlerAddress = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot0));
      const handlerAddressString = handlerAddress.toHexString()

      const structSlot1 = BigNumber.from(newKey).add(1);
      const resourceId = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot1));
      const resourceIdString = resourceId.toHexString()

      const structSlot2 = BigNumber.from(newKey).add(2);
      const statusAndTokenAddress = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot2));
      // 0x0163b2de61aea347c6f39c5211cd7c353aba85c09c, where 0x01 - status; 63b2de61aea347c6f39c5211cd7c353aba85c09c - tokenAddress
      const statusAndTokenAddressString = statusAndTokenAddress.toHexString()
      let status = 0
      let tokenAddress = ''
      if(statusAndTokenAddressString.length > 42) {
        status = parseInt(statusAndTokenAddressString.substr(0, 4), 16)
        tokenAddress = `0x${statusAndTokenAddressString.substr(4)}`
      } else {
        tokenAddress = statusAndTokenAddressString.substr(4)
      }

      list.push({
        id: id,
        status: status,
        requestData: [
          {label: `Handler address: ${handlerAddressString}`},
          {label: `Resource Id: ${resourceIdString}`},
          {label: `Token address: ${tokenAddress}`}
        ],
        type: 'SetResourceRequest',
        votes: votes.toNumber(),
        hasRejectedButton: true,
        haveCancelFunc: true,
        isAvailable
      })
    }
    return { dataList: list, lastId: counter }
  }

  const getSetGenericResourceStruct = async (dao, daoAddress: string, storageSlot: BigNumber, rowsPerPage, currentPage) => {

    const counter = await dao.getSetResourceRequestCount();
    const list = []
    const {start, end} = getStartEnd(currentPage, rowsPerPage, counter.toNumber())

    for(let i = start; i <= end; i++) {
      const id = counter - (i-1)

      const key = BigNumber.from(id);

      const newKey = await getStructSlot(key, storageSlot)
      const votes = await dao.countSetGenericResourceAffirmativeVotes(id)
      const isAvailable = await checkAvailable(dao.isSetGenericResourceAvailable, id)

      const structSlot0 = BigNumber.from(newKey);
      const handlerAddress = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot0));

      const structSlot1 = BigNumber.from(newKey).add(1);
      const resourceId = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot1));

      const structSlot2 = BigNumber.from(newKey).add(2);
      const contractAddressAndDepositFunctionSig = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot2));
      // 0x01c28c23035325af9c8c02f955c40632091d132e26 where 0x01 - deposit functiun sig; c28c23035325af9c8c02f955c40632091d132e26 - contractAddress
      const contractAddressAndDepositFunctionSigString = contractAddressAndDepositFunctionSig.toHexString()

      const structSlot3 = BigNumber.from(newKey).add(3);
      const depositFunctionDepositerOffset = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot3));

      const structSlot4 = BigNumber.from(newKey).add(4);
      const executeFunctionSigAndStatus = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot4));
      const executeFunctionSigAndStatusString = executeFunctionSigAndStatus.toHexString()
      // 0x0100000001 where 0x01 - statu; 0001 - executeFunctionSig
      let status = 0
      let executeFunctionSig = ''
      if(executeFunctionSigAndStatusString.length > 4){
        status = parseInt(executeFunctionSigAndStatusString.substr(0, 4), 16)
        executeFunctionSig = `0x${executeFunctionSigAndStatusString.substr(4)}`
      } else {
        executeFunctionSig = executeFunctionSigAndStatusString
      }
      const depositFunctiunSig = contractAddressAndDepositFunctionSigString.substr(0, 4)
      const contractAddress = contractAddressAndDepositFunctionSigString.substr(4)
      list.push({
        id: id,
        status: status,
        requestData: [
          {label: `Handler Address: ${handlerAddress.toHexString()}`},
          {label: `Resource Id: ${resourceId.toHexString()}`},
          {label: `Deposit Function Depositer Offset: ${depositFunctionDepositerOffset.toHexString()}`},
          {label: `Execute Function Sig: ${executeFunctionSig}`},
          {label: `Deposit Function Sig: ${depositFunctiunSig}`},
          {label: `Contract Address: 0x${contractAddress}`},
        ],
        type: 'SetGenericResourceRequest',
        votes: votes.toNumber(),
        hasRejectedButton: true,
        haveCancelFunc: true,
        isAvailable
      })
    }
    console.log(6)
    return { dataList: list, lastId: counter }
  }

  const  getSetBurnableStruct = async (dao, daoAddress: string, storageSlot: BigNumber, rowsPerPage, currentPage) => {

    const counter = await dao.getSetBurnableRequestCount();
    const list = []
    const {start, end} = getStartEnd(currentPage, rowsPerPage, counter.toNumber())

    for(let i = start; i <= end; i++) {
      const id = counter - (i-1)

      const key = BigNumber.from(id);

      const newKey = await getStructSlot(key, storageSlot);
      const votes = await dao.countSetBurnableAffirmativeVotes(id)
      const isAvailable = await checkAvailable(dao.isSetBurnableAvailable, id)
      const structSlot0 = BigNumber.from(newKey);
      const treshold = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot0));
      const tresholdString = treshold.toHexString()

      const structSlot1 = BigNumber.from(newKey).add(1);
      const tokenAddressAndStatus = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot1));
      // 0x0163b2de61aea347c6f39c5211cd7c353aba85c09c where 0x01 - status; 63b2de61aea347c6f39c5211cd7c353aba85c09c - tpokenAddress
      const tokenAddressAndStatusString = tokenAddressAndStatus.toHexString()
      let status = 0
      let tokenAddress = ''
      if(tokenAddressAndStatusString.length > 42) {
        status =  parseInt(tokenAddressAndStatusString.substr(0, 4))
        tokenAddress =  `0x${tokenAddressAndStatusString.substr(4)}`
      } else {
        tokenAddress = tokenAddressAndStatusString
      }

      list.push({
        id: id,
        status: status,
        requestData: [
          {label: `Treshold: ${tresholdString}`},
          {label: `Token address: ${tokenAddress}`},
        ],
        type: 'SetBurnableRequest',
        votes: votes.toNumber(),
        hasRejectedButton: true,
        haveCancelFunc: true,
        isAvailable
      })
    }
    return { dataList: list, lastId: counter }
  }

  const getSetNonceStruct = async (dao, daoAddress: string, storageSlot: BigNumber, rowsPerPage, currentPage) => {

    const counter = await dao.getSetNonceRequestCount();
    const list = []
    const {start, end} = getStartEnd(currentPage, rowsPerPage, counter.toNumber())

    for(let i = start; i <= end; i++) {
      const id = counter - (i-1)

      const key = BigNumber.from(id);

      const newKey = await getStructSlot(key, storageSlot);
      const votes = await dao.countSetNonceAffirmativeVotes(id)
      const isAvailable = await checkAvailable(dao.isSetNonceAvailable, id)

      const structSlot0 = BigNumber.from(newKey);
      const domainIdAndNonceAndStatus = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot0));
      // 0x01000000000000270f01 where 0x01 - status; 000000000000270f - nonce; 01 - domainId
      const domainIdAndNonceAndStatusString = domainIdAndNonceAndStatus.toHexString()
      let status = 0
      let nonce = 0
      let domainId = 0
      if(domainIdAndNonceAndStatusString.length > 6) {
        status = parseInt(domainIdAndNonceAndStatusString.substr(0, 4))
        nonce = parseInt(`0x${domainIdAndNonceAndStatusString.substr(4, 16)}`)
        domainId = parseInt(`0x${domainIdAndNonceAndStatusString.substr(20, 4)}`)
      } else {
        nonce = parseInt(`${domainIdAndNonceAndStatusString.substr(0, 4)}`)
        domainId = parseInt(`0x${domainIdAndNonceAndStatusString.substr(4)}`)
      }

      list.push({
        id: id,
        status: status,
        requestData: [
          {label: `Nonce: ${nonce}`},
          {label: `Domain Id: ${domainId}`},
        ],
        type: 'SetNonceRequest',
        votes: votes.toNumber(),
        hasRejectedButton: true,
        haveCancelFunc: true,
        isAvailable
      })
    }
    console.log(8)
    return { dataList: list, lastId: counter }
  }

  const getSetForwarderStruct = async (dao, daoAddress: string, storageSlot: BigNumber, rowsPerPage, currentPage) => {

    const counter = await dao.getSetForwarderRequestCount();
    const list = []
    const {start, end} = getStartEnd(currentPage, rowsPerPage, counter.toNumber())

    for(let i = start; i <= end; i++) {
      const id = counter - (i-1)

      const key = BigNumber.from(id);

      const newKey = await getStructSlot(key, storageSlot);
      const votes = await dao.countSetForwarderAffirmativeVotes(id)
      const isAvailable = await checkAvailable(dao.isSetForwarderAvailable, id)
      const structSlot0 = BigNumber.from(newKey);
      const forwarderAddressAndValidAndStatus = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot0));
      // 0x0100012a1b8f33cefc233618716ca50fd670a784bb51 where 0x01 - status; 0001 - valid; 12a1b8f33cefc233618716ca50fd670a784bb51 - forwarderAddress
      // 0x0100cafecafecafecafecafecafecafecafecafecafe where 0x01 - status; 000 - valid; cafecafecafecafecafecafecafecafecafecafe - forwarderAddress
      const forwarderAddressAndValidAndStatusString = forwarderAddressAndValidAndStatus.toHexString()
      let status = 0
      let valid = ''
      let forwarderAddress = ''
      if(forwarderAddressAndValidAndStatusString.length > 42) {
        status =  parseInt(forwarderAddressAndValidAndStatusString.substr(0, 4), 16)
        valid = `0x${forwarderAddressAndValidAndStatusString.substr(4, 2)}`
        forwarderAddress = `0x${forwarderAddressAndValidAndStatusString.substr(6)}`
      } else {
        forwarderAddress = forwarderAddressAndValidAndStatusString
      }

      list.push({
        id: id,
        status: status,
        requestData: [
          {label: `Valid: ${valid ? parseInt(valid, 16) ? 'true' : 'false' : ''}`},
          {label: `Forwarder address: ${forwarderAddress}`},
        ],
        type: 'SetForwarderRequest',
        votes: votes.toNumber(),
        hasRejectedButton: true,
        haveCancelFunc: true,
        isAvailable
      })
    }
    return { dataList: list, lastId: counter }
  }

  const getChangeFeeStruct =async (dao, daoAddress: string, storageSlot: BigNumber, signer, rowsPerPage, currentPage) => {

    const counter = await dao.getChangeFeeRequestCount();
    const list = []
    const {start, end} = getStartEnd(currentPage, rowsPerPage, counter.toNumber())

    for(let i = start; i <= end; i++) {
      const id = counter - (i-1)

      const key = BigNumber.from(id);

      const newKey = await getStructSlot(key, storageSlot);
      const votes = await dao.countGetChangeFeeAffirmativeVotes(id)
      const isAvailable = await checkAvailable(dao.isChangeFeeAvailable, id)
      const structSlot0 = BigNumber.from(newKey);
      const tokenAddressAndChainId = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot0));
      const tokenAddressAndChainIdString = tokenAddressAndChainId.toHexString()
      // 0x018748ad8c703bb5054c08a9064699be4a96285131 where 0x01 - chainId; 8748ad8c703bb5054c08a9064699be4a96285131 - tokenAddress

      const structSlot1 = BigNumber.from(newKey).add(1);
      const basicFee = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot1));

      const structSlot2 = BigNumber.from(newKey).add(2);
      const minAmount = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot2));

      const structSlot3 = BigNumber.from(newKey).add(3);
      const maxAmount = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot3));

      const structSlot4 = BigNumber.from(newKey).add(4)
      const status = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot4));

      const chainId = tokenAddressAndChainIdString.substr(0,4)
      const tokenAddress = tokenAddressAndChainIdString.substr(4)
      const basicFeeInt = await tokenAmountToInt(tokenAddress, basicFee, signer)
      const minAmountInt = await tokenAmountToInt(tokenAddress, minAmount, signer)
      const maxAmountInt = await tokenAmountToInt(tokenAddress, maxAmount, signer)
      list.push({
        id: id,
        type: 'ChangeFeeRequest',
        status: status.toNumber(),
        requestData: [
          {label: `Chain Id: ${chainId}`},
          {label: `Token Address: 0x${tokenAddress}`},
          {label: `Basic Fee: ${basicFeeInt}`},
          {label: `Min Amount: ${minAmountInt}`},
          {label: `Max Amount: ${maxAmountInt}`},
        ],
        votes: votes.toNumber(),
        hasRejectedButton: true,
        haveCancelFunc: true,
        isAvailable
      })
    }
    console.log(10)
    return { dataList: list, lastId: counter }
  }

  const getChangeFeePercentStruct = async (dao, daoAddress: string, storageSlot: BigNumber, rowsPerPage, currentPage) => {

    const counter = await dao.getChangeFeePercentRequestCount();
    const list = []
    const {start, end} = getStartEnd(currentPage, rowsPerPage, counter.toNumber())

    for(let i = start; i <= end; i++) {
      const id = counter - (i-1)

      const key = BigNumber.from(id);

      const newKey = await getStructSlot(key, storageSlot);
      const votes = await dao.countGetChangeFeePercentAffirmativeVotes(id)
      const isAvailable = await checkAvailable(dao.isChangeFeePercentAvailable, id)
      const structSlot0 = BigNumber.from(newKey);
      const feeMaxValueAndFeePercentAndStatus = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot0));
      // 0x010000000000000063000000000000000000000000000182b8 where 0x01 - status; 0000000000000063 - feePercent; 000000000000000000000000000182b8 - feeMaxValue
      const feeMaxValueAndFeePercentAndStatusString = feeMaxValueAndFeePercentAndStatus.toHexString()
      let status = 0
      let feePercent = 0
      let feeMaxValue = 0
      if(feeMaxValueAndFeePercentAndStatusString.length > 40) {
        status = parseInt(feeMaxValueAndFeePercentAndStatusString.substr(0, 4))
        feePercent = parseInt(`0x${feeMaxValueAndFeePercentAndStatusString.substr(4, 16)}`, 16)
        feeMaxValue = parseInt(`0x${feeMaxValueAndFeePercentAndStatusString.substr(20)}`, 16)

      } else {
        feePercent = parseInt(`${feeMaxValueAndFeePercentAndStatusString.substr(0, 4)}`, 16)
        feeMaxValue = parseInt(`0x${feeMaxValueAndFeePercentAndStatusString.substr(20)}`, 16)
      }

      list.push({
        id: id,
        status: status,
        requestData: [
          {label: `Fee percent: ${feePercent}`},
          {label: `Fee max value: ${feeMaxValue}`},
        ],
        type: 'ChangeFeePercentRequest',
        votes: votes.toNumber(),
        hasRejectedButton: true,
        haveCancelFunc: true,
        isAvailable
      })
    }
    return { dataList: list, lastId: counter }
  }

  const getWithdrawStruct = async (dao, daoAddress: string, storageSlot: BigNumber, signer: any, rowsPerPage, currentPage) =>  {
    const counter = await dao.getWithdrawRequestCount();
    const list = []
    const {start, end} = getStartEnd(currentPage, rowsPerPage, counter.toNumber())

    for(let i = start; i <= end; i++) {
      const id = counter - (i-1)

      const key = BigNumber.from(id);

      const newKey = await getStructSlot(key, storageSlot)
      const votes = await dao.countGetWithdrawAffirmativeVotes(id)
      const isAvailable = await checkAvailable(dao.isWithdrawAvailable, id)

      const structSlot0 = BigNumber.from(newKey);
      const handlerAddress = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot0));

      const structSlot1 = BigNumber.from(newKey).add(1);
      const bytesKey = BigNumber.from(ethers.utils.keccak256(structSlot1.toHexString()));

      const tokenAddress = BigNumber.from(await provider.getStorageAt(daoAddress, bytesKey));
      const tokenAddressString = tokenAddress.toHexString()

      const recepientAddress = BigNumber.from(await provider.getStorageAt(daoAddress, bytesKey.add(1).toHexString()));
      const amount = BigNumber.from(await provider.getStorageAt(daoAddress, bytesKey.add(2).toHexString()));
      const intAmount = await tokenAmountToInt(tokenAddressString, amount, signer)
      const structSlot2 = BigNumber.from(newKey).add(2);
      const status = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot2));
      const haveCancelFunc = [1230, 4002].includes(signer.provider._network.chainId)
      list.push({
        id: id,
        status: status.toNumber(),
        requestData: [
          {label: `handlerAddress: ${handlerAddress.toHexString()}`},
          {label: `tokenAdderess: ${tokenAddressString}`},
          {label: `recepientAddress: ${recepientAddress.toHexString()}`},
          {label: `amount: ${intAmount}`},
        ],
        type: 'WithdrawRequest',
        votes: votes.toNumber(),
        hasRejectedButton: true,
        haveCancelFunc: haveCancelFunc,
        isAvailable
      })
    }
    console.log(12)
    return { dataList: list, lastId: counter }
  }

  const getSetTreasuryStruct = async (dao, daoAddress: string, storageSlot: BigNumber, rowsPerPage, currentPage) => {

    const counter = await dao.getSetTreasuryRequestCount();
    const list = []
    const {start, end} = getStartEnd(currentPage, rowsPerPage, counter.toNumber())

    for(let i = start; i <= end; i++) {
      const id = counter - (i-1)

      const key = BigNumber.from(id);

      const newKey = await getStructSlot(key, storageSlot)
      const votes = await dao.countGetSetTreasuryAffirmativeVotes(id)
      const isAvailable = await checkAvailable(dao.isSetTreasuryAvailable, id)
      const structSlot0 = BigNumber.from(newKey);
      const statusAndTreasuryAddress = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot0));
      // 0x01cafecafecafecafecafecafecafecafecafecafe where 0x01 - status; cafecafecafecafecafecafecafecafecafecafe - treasuryAddress
      const statusAndTreasuryAddressString = statusAndTreasuryAddress.toHexString()
      let status = 0
      let treasuryAddress = ''
      if(statusAndTreasuryAddressString.length > 42) {
        status =  parseInt(statusAndTreasuryAddressString.substr(0, 4), 16)
        treasuryAddress = `0x${statusAndTreasuryAddressString.substr(4)}`
      } else {
        treasuryAddress = statusAndTreasuryAddressString
      }
      list.push({
        id: id,
        status: status,
        requestData: [
          {label: `Treasury address: ${treasuryAddress}`},
        ],
        type: 'SetTreasuryRequest',
        votes: votes.toNumber(),
        hasRejectedButton: true,
        haveCancelFunc: true,
        isAvailable
      })
    }
    console.log(13)
    return { dataList: list, lastId: counter }
  }

  const getSetNativeTokensForGasStruct = async (dao, daoAddress: string, storageSlot: BigNumber) => {

    const counter = await dao.getSetNativeTokensForGasRequestCount();

    for(let i = 1; i <= counter; i++) {
      const id = counter - (i-1)

      const key = BigNumber.from(id);

      const newKey = await getStructSlot(key, storageSlot)

      const structSlot0 = BigNumber.from(newKey);
      const amount = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot0));
    }
  }

  const getTransferNativeStruct = async (dao, daoAddress: string, storageSlot: BigNumber) => {

    const counter = await dao.getTransferNativeRequestCount();

    for(let i = 1; i <= counter; i++) {
      const id = counter - (i-1)

      const key = BigNumber.from(id);

      const newKey = await getStructSlot(key, storageSlot)

      const structSlot0 = BigNumber.from(newKey);
      const recepient = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot0));

      const structSlot1 = BigNumber.from(newKey).add(1);
      const amount = BigNumber.from(await provider.getStorageAt(daoAddress, structSlot1));
    }
  }

  return {
    getTransferStruct, getVoterStruct, getOwnerChangeStruct, getPauseStatusStruct,
    getTransferNativeStruct, getSetNativeTokensForGasStruct, getSetTreasuryStruct,
    getWithdrawStruct, getChangeFeePercentStruct, getChangeFeeStruct, getSetForwarderStruct,
    getSetNonceStruct, getSetBurnableStruct, getSetGenericResourceStruct,
    getChangeRelayerThresholdStruct, getSetResourceStruct
  }
}