import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { Contract, ethers } from 'ethers'
import { find } from 'lodash'
import { useMetaMask } from 'metamask-react'

import { FormConnectWallet } from '../components/forms'
import { CustomTab } from '../components/ui'
import { Liquidity, Settings } from '../components/common'
import DefaultAlert from '../components/common/DefaultAlert'
import { CircularLoader } from '../components/ui/CircularLoader'
import { NewProposal } from '../components/forms/NewProposal'

import { useSupport } from '../hooks/useSupport'
import daoRevenueShareAbi from '../constant/revenue-share/dao-revenue-share-abi';
import revenueShareAbi from '../constant/revenue-share/revenue-share-abi';
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { availableChainIds, daoAddresses, providerUrls, rpcUrls } from '../constant/chain/chain'
import makeDAORevenueShareObj from '../utils/daoObjects/createDAORevenueShareObject';
import { useDAO } from '../hooks/useDAO'
import { daoStatuses } from '../constant/common/commonConst'
import { daoRequestsSlice } from '../store/reducers/daoRequestsSlice'
import usePagination from '../hooks/usePagination'

const DaoRevenueShare: React.FC = () => {
    const dispatch = useAppDispatch();

    const {connectWallet} = useSupport()
    const {isConnect} = useAppSelector(state => state.walletReducer)
    const {chainId} = useMetaMask()
// @ts-ignore
    const provider = useMemo(() => new ethers.providers.JsonRpcProvider(providerUrls[chainId], {
        chainId: parseInt(chainId, 16)
    }), [chainId])

    const {setRevenueShareData} = daoRequestsSlice.actions;

    const [tableData, setTableData] = useState([])
    const [DAOMap, setDAOMap] = useState([])
    const [alert, setAlert] = useState(null)
    const [votersList, setVotersList] = useState([])
    const [openProgress, setOpenProgress] = useState(false)
    const [selectedType, setSelectedType] = useState('')
    const [currentDao, setCurrentDao] = useState(null)
    const [addresses, setAddresses] = useState([])

    const {makeDataForNewDao, getCurrentVoters, cancelRequest, approveRequest, sendVote} = useDAO(provider)
    const {currentPage, rowsPerPage, count, setCount, handlePageChange, handleRowsPerPageChange} = usePagination()

    const getData = async () => {

        try {
            setOpenProgress(true)
            const {signer} = await connectWallet()
            const DAORevenueShareAddress = daoAddresses[chainId]?.DAORevenueShareAddress
            const revenueShareAddress = daoAddresses[chainId]?.revenueShareAddress

            const DAO = new Contract(DAORevenueShareAddress, daoRevenueShareAbi, signer)
            const revenueShare = new Contract(revenueShareAddress, revenueShareAbi, signer)

            const daoActionMap = makeDAORevenueShareObj(DAO, revenueShare, DAORevenueShareAddress, provider)
            let selectedDao
            if(selectedType){
                selectedDao = find(daoActionMap, {type: selectedType})
            } else {
                setSelectedType(daoActionMap[0].type)
                selectedDao = find(daoActionMap, {type: daoActionMap[0].type})
            }
            const { votersList, voterCount } = await getCurrentVoters(DAO)
            const { tableData, lastId } = await makeDataForNewDao(selectedDao, voterCount, signer, rowsPerPage, currentPage);
            setCount(lastId)

            dispatch(setRevenueShareData({requests: tableData, voters: votersList }))
            setAddresses([
                {name: `DAO Address: ${DAORevenueShareAddress}`},
                {name: `Revenue share Address: ${revenueShareAddress}`},
            ])
            setCurrentDao(selectedDao)
            setDAOMap(daoActionMap)
            setVotersList(votersList)
            setTableData(tableData)

        }catch (err) {
            console.log(err)
            setTableData([])
            setVotersList([])
            setAlert({open: true, type: 'error', text: 'something went wrong'})
        } finally {
            setOpenProgress(false)
        }
    }

    const makeVote = async (type, id, voteType) => {
        await sendVote(currentDao, type, id, setAlert, voteType)
    }

    const handleCancelRequest = async (type, id) => {
        await cancelRequest(currentDao, id, setAlert)
    }

    const handleApproveRequest = async (type, id) => {
        await approveRequest(currentDao, id, setAlert)
    }

    useEffect(() => {
        if(isConnect && availableChainIds.includes(chainId)){
            setOpenProgress(true)
            getData()
        } else {
            setTableData([])
        }
    }, [isConnect, chainId, selectedType, currentPage, rowsPerPage])



    // @ts-ignore
    return <>
        <CircularLoader
            open={openProgress}
        />
        <DefaultAlert
            open={alert?.open}
            type={alert?.type}
            text={alert?.text}
            onClose={() => setAlert(null)}
        />
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <Typography variant='h4' sx={{ px: 2, mb: 4 }}>
                    DAO revenue share
                </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
                <FormConnectWallet onChainChangeTrigger={handlePageChange}/>
            </Grid>
        </Grid>
        <CustomTab
            items={[
                {
                    label: 'Settings',
                    component:
                        <Settings
                            addresses={addresses}
                            getData={getData}
                            selectedType={selectedType}
                            setSelectedType={setSelectedType}
                            DAOMap={DAOMap}
                            statuses={daoStatuses}
                            tableData={tableData}
                            makeVote={makeVote}
                            handleCancelRequest={handleCancelRequest}
                            handleApproveRequest={handleApproveRequest}
                            votersList={votersList}
                            currentPage={currentPage}
                            rowsPerPage={rowsPerPage}
                            count={count}
                            onPageChange={handlePageChange}
                            onRowCountChange={handleRowsPerPageChange}
                        />,
                },
                {
                    label: 'Liquidity',
                    component: <Liquidity />,
                },
            ]}
        />
        {DAOMap.length &&
            <NewProposal
                daoMap={DAOMap}
                setAlert={setAlert}
            />
        }
    </>
}

export default DaoRevenueShare;
