import React from 'react'
import { FormControl, Grid, MenuItem, OutlinedInput, Theme, useTheme } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import LoadingButton from '@mui/lab/LoadingButton'
import { cutUserAddress } from '../../utils/helpers'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { useSupport } from '../../hooks/useSupport'
import { walletSlice } from '../../store/reducers/walletSlice'
import { availableChains } from '../../constant/chain/chain'
import { useMetaMask } from 'metamask-react'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

const FormConnectWallet = ({onChainChangeTrigger}: any) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const dispatch = useAppDispatch()
  const {isConnect, address} = useAppSelector(state => state.walletReducer)
  const {web3modal} = useAppSelector(state => state.web3modalReducer)
  const {setWalletInfo} = walletSlice.actions
  const {connectWallet} = useSupport()
  const { switchChain, chainId } = useMetaMask();

  const connect = async () => {
    setLoading(true)
    await connectWallet()
    setLoading(false)
  }

  const disconnect = async () => {
    // @ts-ignore
    await web3modal.clearCachedProvider();
    dispatch(setWalletInfo({isConnect: false, address: ''}))
  }

  const handleChange = async (event: SelectChangeEvent) => {
    onChainChangeTrigger(null, 0)
    await switchChain(event.target.value)
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <FormControl sx={{ width: '100%' }}>
          <Select
            displayEmpty
            value={chainId}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              return availableChains[selected]?.chainName;
            }}
            MenuProps={MenuProps}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {Object.values(availableChains).map((chain) => (
              <MenuItem
                key={chain.chainId}
                value={chain.chainId}
              >
                {chain.chainName }
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
        <LoadingButton
          sx={{ width: '100%' }}
          size="large"
          variant="contained"
          loading={loading}
          disabled={false}
          onClick={isConnect ? disconnect : connect}>
          {isConnect ? 'Disconnect' : 'Connect'} wallet

        </LoadingButton>
      </Grid>
      <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
        {address && cutUserAddress(address)}
      </Grid>
    </Grid>
  )
}

export default FormConnectWallet
