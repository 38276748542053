import { Box, Button, Divider, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useDAO } from '../../hooks/useDAO'
import { createProposalForm } from '../../utils/createProposalForm'
import { useSupport } from '../../hooks/useSupport'
import { ethers } from 'ethers'
import { rpcUrls } from '../../constant/chain/chain'
import { useMetaMask } from 'metamask-react'

export const NewProposal = ({ daoMap, setAlert }: any) => {
  const {chainId} = useMetaMask()

  // @ts-ignore
  const provider = useMemo(() => new ethers.providers.JsonRpcProvider(rpcUrls[chainId], {
    chainId: parseInt(chainId, 16)
  }), [chainId])

  const {getCurrentDao} = useDAO(provider)
  const {connectWallet} = useSupport()

  const [proposalType, setProposalType] = useState(daoMap[0]?.type)
  const [proposalData, setProposalData] = useState({})
  const [proposalForm, setProposalForm] = useState([])
  const [isAddNew, setIsAddNew] = useState('init')

  useMemo(() => {
    if(isAddNew === 'add'){
      const schema = getCurrentDao(daoMap, proposalType || daoMap[0].type).addingSchemaPart(proposalForm.length)
      const newFormPart = createProposalForm(schema)
      const draftForm = [...proposalForm]
      draftForm.splice(proposalForm.length - 2, 2)
      setProposalForm([...draftForm, ...newFormPart])
      setIsAddNew('no update')
    } else if(isAddNew === 'init'){
      const schema = getCurrentDao(daoMap, proposalType || daoMap[0].type).newProposalSchema
      const newData = {}
      schema?.map(el => {newData[el.formLink] = el.defaultValue})
      setProposalData(newData)
      setProposalForm(createProposalForm(schema))
    }
  }, [proposalType, isAddNew])

  const handleChangeProposalType = (value) => {
    setIsAddNew('init')
    setProposalType(value)
  }

  const handleChange = (e, formLink) => {
    setProposalData(prevState => ({...prevState, [formLink]: e.target.value}))
  }

  const handleAddNew = () => {
   setIsAddNew('add')
  }

  const handleSubmitProposal = async () => {
    try {
      const {signer} = await connectWallet()
      const currentDao = getCurrentDao(daoMap, proposalType)
      await currentDao.createNewProposal(proposalData, signer)
    } catch (err) {
      console.log(err)
      setAlert({open: true, type: 'error', text: err.message})
    }

  }

  return (
    <Box>
      <Divider/>
      <Box sx={{p: 2}}>
        <Typography variant={'h4'}>
          Create proposal
        </Typography>
        <Select
          value={proposalType}
          onChange={(e) => handleChangeProposalType(e.target.value)}
          variant={'outlined'}
          sx={{minWidth: 220, mt: 2}}
          size={'small'}
        >
          {daoMap?.map(el =>
            <MenuItem key={el.type} value={el.type}>
              {el.name}
            </MenuItem>
          )}
        </Select>
        <Grid container  spacing={1} sx={{maxWidth: proposalForm.length > 10 ? 'fit-content' : 'inherit'}}>
          {proposalForm.map(el =>
            el.type === 'addNew' ?
              el.element(handleAddNew)
              :
              el.element(proposalData[el.formLink], (e) => handleChange(e, el.formLink))
          )}
        </Grid>
        <Box sx={{mt: 2}}>
          <Button variant={'outlined'} onClick={handleSubmitProposal}>
            Submit Proposal
          </Button>
        </Box>
      </Box>
    </Box>
  )
}