import React, { useMemo, useState } from 'react'
import {
  Box,
  Button, Grid, MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from '@mui/material'
import { filter } from 'lodash'

const Settings = ({
                    tableData, makeVote, handleCancelRequest, votersList, addresses,
                    handleApproveRequest, statuses, DAOMap, selectedType, setSelectedType, getData,
                    onPageChange, onRowCountChange, currentPage, rowsPerPage, count
}: any) => {

  const [selectedStatus, setSelectedStatus] = useState('all')


  const filteredData = useMemo(() => {
    if(selectedStatus !== 'all') {
      return filter(tableData, {status: Number(selectedStatus)})
    } else return  tableData
  }, [tableData, selectedStatus, selectedType])

  const thresholdCount = useMemo(() => {
    return Math.floor(votersList?.length/2 + 1) || 0
  }, [votersList?.length])

  const checkStatus = (item) => {
    return !(statuses[item.status] === 'active')
  }

  return (
    <>
      <Grid container spacing={1} justifyContent={'space-between'}>
        <Grid item>
          <Select
            onChange={(ev) => setSelectedStatus(ev.target.value)}
            input={<OutlinedInput />}
            inputProps={{ 'aria-label': 'Without label' }}
            renderValue={(selected) => {
              return selected === 'all' ? 'All' : statuses[selected];
            }}
            size={'small'}
            sx={{width: 200}}
            value={selectedStatus}
          >
            <MenuItem
              key={'all'}
              value={'all'}
            >
              All
            </MenuItem>
            {Object.entries(statuses).map(([key, value]: any) =>
              <MenuItem
                key={key}
                value={key}
              >
                {value}
              </MenuItem>
            )}
          </Select>
          <Select
            onChange={(ev) => setSelectedType(ev.target.value)}
            input={<OutlinedInput />}
            inputProps={{ 'aria-label': 'Without label' }}
            size={'small'}
            sx={{width: 200}}
            value={selectedType}
          >
            {DAOMap.map((el: any) =>
              <MenuItem
                key={el.type}
                value={el.type}
              >
                {el.name}
              </MenuItem>
            )}
          </Select>
        </Grid>
        <Grid item>
          {addresses.map(el => <div key={el.name}>{el.name}</div>)}
        </Grid>
        <Grid item>
          <Button variant={'outlined'} onClick={getData}>
            Update
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell align="left">Request type</TableCell>
              <TableCell align="left">Request params</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Voted</TableCell>
              <TableCell align="center">Submit vote</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData && filteredData.map((item, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {item.id}
                </TableCell>

                <TableCell align="left">{item.type}</TableCell>
                <TableCell align="left">
                  {item.requestData?.map((el, index) =>
                    <div key={index}>{el.label}</div>
                  )}
                </TableCell>
                <TableCell align="left">{statuses[item.status]}</TableCell>
                <TableCell align="left">{item.votes}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    disabled={checkStatus(item)}
                    onClick={() => {makeVote(item.type, item.id, true)}}
                    sx={{marginRight: 1}}
                  >
                    Approve
                  </Button>
                  {item.hasRejectedButton &&
                    <Button
                      variant="contained"
                      disabled={checkStatus(item)}
                      onClick={() => {makeVote(item.type, item.id, false)}}
                    >
                      Reject
                    </Button>
                  }

                </TableCell>
                <TableCell align="center">
                  {item.isAvailable &&
                  <Button
                    variant="contained"
                    color="success"
                    disabled={checkStatus(item)}
                    onClick={() => handleApproveRequest(item.type, item.id)}
                    sx={{marginRight: 1}}
                  >
                    Submit
                  </Button>
                  }
                  {item.haveCancelFunc &&
                    <Button
                      variant="contained"
                      color="error"
                      disabled={checkStatus(item)}
                      onClick={() => handleCancelRequest(item.type, item.id)}
                    >
                      Delete
                    </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        page={currentPage}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowCountChange}
      />
      <Grid container sx={{marginTop: 0}} spacing={5}>
        <Grid item>
          <Box>
            {`Current voters (${votersList?.length})`}
          </Box>
          {votersList?.map(el =>
            <Box key={el}>
              {el}
            </Box>
          )}
        </Grid>
        <Grid item>
          <Box>
            {`Threshold: ${thresholdCount}`}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Settings
